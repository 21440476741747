.contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form-message {
    max-height: 500px;
}

.contact-card{
    padding-left: 16px;
    padding-right: 16px;
    background-color: rgba(39, 39, 39, 0.123);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    width: fit-content;
    height: 100%;
    margin: auto;
    margin-top: 10vh !important;
}

input, textarea {
    &.contact-input{
        background-color: #22171700;
        color: #dadada;
        border-color: #2d2d2d4f !important;
        border-style: solid;
        border-width: 1px;
    }
    &.contract-input:focus{
        background-color: #2d2d2d4f;
    }
    &:focus::placeholder{
        color:transparent;
    }
}